<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>베팅규정</sub-title>
                <div class="board_list">

                    <div class="notice_list">
                        <div class="n text-orange bg_title">
                            No
                        </div>
                        <div class="t text-orange bg_title">
                            베팅규정
                        </div>
                    </div>
                    <div class="notice_list" v-for="(b,index) in betRuleList" :key="index">
                        <div class="n">
                            {{index+1}}
                        </div>
                        <div class="t" :style="{'color':b.titleColor}" @click="showContent(b.id)">
                            {{b.title}}
                        </div>
                        <div class="c" v-if="clickNumber === b.id"  v-html="b.content">

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {getBetRuleList, getNoticeList} from "../../network/userRequest";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import SubTitle from "../../components/SubTitle";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";

    export default {
        name: "BetRule",
        mixins:[postionMixin],
        components: {SubTitle, FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp},
        data(){
            return {
                sportsConst,
                betRuleList:[],
                clickNumber:0,
                position:"베팅규정",
            }
        },
        methods:{
            showContent(id){
                if(id === this.clickNumber){
                    this.clickNumber = 0
                }else {
                    this.clickNumber = id;
                }

            }
        },
        created() {
            this.$store.commit(RECEIVE_SHOW_LOADING)
            getBetRuleList().then(res=>{
                if (res.data.success) {
                    this.betRuleList = res.data.data
                }
                this.$store.commit(RECEIVE_HIDE_LOADING)
            })
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
</style>